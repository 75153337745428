<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->

      <Header />
      <!-- /Brand logo-->

      <!-- Left Text-->

      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <CoverImage />
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col
        lg="4"
        class=" align-items-center auth-bg px-2 p-lg-5"
      >
        <div class="row">
          <div class=" mx-auto col-12 margin-top-icon-sm">
            <MediaIcon />
          </div>
          <div class="px-xl-2 mx-auto col-12 text-margin-top-sm">
            <b-card-title class="mb-1">
              Forgot Password? 🔒
            </b-card-title>
            <b-card-text class="mb-2">
              Enter your associate phone number with your username and we'll send you instructions to reset your
              password
            </b-card-text>

            <validation-observer ref="passwordReset">
              <b-form
                class="auth-forgot-password-form mt-2"
                @submit.prevent="submitUsername"
              >
                <!-- username -->
                <b-form-group
                  label="User Name"
                  label-for="forgot-password-userName"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="username"
                    vid="username"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        id="reset-password-new"
                        v-model="username"
                        :state="errors.length > 0 ? false:null"
                        class="form-control-merge"
                        name="forgot-password-userName"
                        placeholder="Enter username"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-button
                  type="submit"
                  variant="primary"
                  block
                  :disabled="isDisabled"
                >
                  Continue
                </b-button>
              </b-form>
            </validation-observer>

            <p class="text-center mt-2">
              <b-link
                :to="{name:'auth-login'}"
              >
                <feather-icon icon="ChevronLeftIcon" />
                Back to login
              </b-link>
            </p>
          </div>
        </div>
      </b-col>
      <!-- /Forgot password-->

      <!-- /footer-->
      <b-col
        lg="12"
        class="mt-auto"
      >
        <Footer />
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import {
  BRow, BCol, BLink, BCardTitle, BCardText, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'

import Header from '@/@core/components/landding-page/Header.vue'
import MediaIcon from '@/@core/components/landding-page/MediaIcon.vue'
import Footer from '@/@core/components/landding-page/Footer.vue'
import CoverImage from '@/@core/components/landding-page/CoverImage.vue'
import { mapActions } from 'vuex'
import { required } from '@core/utils/validations/validations'

export default {
  name: 'PasswordReset',
  components: {
    BInputGroupAppend,
    BInputGroup,
    // VuexyLogo,
    BRow,
    BCol,
    BLink,
    // BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
    Header,
    MediaIcon,
    Footer,
    CoverImage,
  },
  data() {
    return {
      isDisabled: true,
      required,
    }
  },
  computed: {
    username: {
      get() {
        return this.$store.state['forgot-password'].username
      },
      set(value) {
        this.$store.commit('forgot-password/setUsername', value)
      },
    },
  },

  watch: {
    username(val) {
      if (val === '') {
        this.isDisabled = true
      } else {
        this.isDisabled = false
      }
    },
  },

  created() {

  },
  methods: {
    ...mapActions('forgot-password', ['submitUsername']),
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
